import {CartContext} from '../CartContext';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
function ProductCard(props){

    const product = props.product;
    const cart = useContext(CartContext);
    return(
        <>
            <div className='containerbox'>
                <Link to={`/produs/${product.id}/${product.url}`}>
                    <div className='container-img'>
                            <img alt={product.title} src={`https://pixelplus.store/store/${product.url}/${product.url}.png`}/>
                            
                            {product.discountprice != product.storeprice ?
                                <div className='discount'>{product.discount}% REDUCERE</div>
                            :
                            <></>
                            }
                            </div>
                </Link>
                <div className='container-footer'>
                    <div className='container-title'>
                        {product.title}
                    </div>
                    {cart.quantity} 
                    <div className='container-footer-info'>
                        <div className='container-price'>
                            <span>{product.storeprice},00 RON</span> {product.discountprice != product.storeprice ? <s>{product.discountprice},00 RON</s> :<></>}
                        </div>
                        <button  className='container-add-to-cart' disabled={cart.getProductQuantity(product.id) >= product.stock} onClick = {() => cart.addOneToCart(product.id, product.product_id,product.title, product.storeprice,product.stock)}>{cart.getProductQuantity(product.id) >= product.stock ? <div className='product-stock-low'>Stoc epuizat</div>:<div>Adaugă în coș<span></span></div>}</button>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ProductCard;