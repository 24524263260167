import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './components/UI/style.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Error404 from './components/Pages/Err-404';
import ErrorOrder from './components/Pages/Err-order';
import HomePage from './components/Pages/Home';
import ProductPage from './components/Pages/ProductPage';
import SuccessPage from './components/Pages/Success';
import CancelPage from './components/Pages/Cancel';
import Termeni from './components/Pages/Termeni-conditii';
import CartProvider from './components/CartContext';
import PlaceOrder from './components/PlaceOrder';
import Politici from './components/Pages/Politica-confidentialitate';
import PoliticaLivrare from './components/Pages/Politica-livrare';
import PoliticaRetur from './components/Pages/Politica-retur';
import Contact from './components/Pages/Contact';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
<script src="https://cdn.jsdelivr.net/npm/react/umd/react.production.min.js" crossorigin></script>
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CartProvider>
      <BrowserRouter>
        <Navbar/>
        <div className='wrapper'>
              <Routes>
                <Route index element={<HomePage title="Pixelplus.store - Avem grija de gadget-ul tau"/>}/>
                <Route path="placeorder" element={<PlaceOrder title="Pixelplus.store - Coșul tău de cumpărături"/>}/>
                <Route path="success" element={<SuccessPage title="Pixelplus.store - Comandă efectuată cu succes"/>}/>
                <Route path="cancel" element={<CancelPage title="Pixelplus.store - Comandă anulată"/>}/>
                <Route path="termeni-si-conditii" element={<Termeni title="Pixelplus.store - Termeni și condiții"/>}/>
                <Route path="politici-de-confidentialitate" element={<Politici title="Pixelplus.store - Politici de confidențialitate"/>}/>
                <Route path="politici-livrare" element={<PoliticaLivrare title="Pixelplus.store - Politici de livrare"/>}/>
                <Route path="politici-retur" element={<PoliticaRetur title="Pixelplus.store - Politici de retur"/>}/>
                <Route path="contact" element={<Contact  title="Pixelplus.store - Contact"/>}/>
                <Route path="/produs/:id/:productName" element={<ProductPage/>}/>
                <Route path="errororder" element={<ErrorOrder title="Pixelplus.store - Eroare comandă"/>}/>
                <Route path="*" element={<Error404 title="Pixelplus.store - Error 404"/>} />
              </Routes>
        </div>
        <Footer/>
      </BrowserRouter>
    </CartProvider>
  </React.StrictMode>
);