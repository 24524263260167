import { useEffect, useState, useContext } from "react";
import {useParams} from "react-router-dom"
import { FetchProducts } from "../ProductsList";
import { getProductData } from "../ProductsList";
import { CartContext } from "../CartContext";

function ProductPage(){
    const {id} = useParams()
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const product = getProductData(id);
    const cart = useContext(CartContext);

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                await FetchProducts();
            } catch (err) {
                setError(true);
            }
            setLoading(false);
        };
        fetchProducts();
    }, []);

    const replaceImageTags = (description) => {
        return description.replace(/\[img=(\d+)\]/g, (match, img) => {
            return `<img src="https://pixelplus.store/store/${product.url}/${img}.png" alt="{${product.title}}" />`;
        });
    };

    if (loading) {
        return <div className='loading'></div>;
    }
    if (error) {
        return <div className='error'>A apărut o eroare. Te rog să dai refresh la pagină!</div>;
    }
    document.title = product.title + " - Pixelplus.store";;

    return(
        <div className='product-page'>
            <div className='product-page-w'>
                    <img alt={product.title} src={`https://pixelplus.store/store/${product.url}/${product.url}.png`}/>
            </div>
            <div className='product-page-r'>
                    <h1>{product.title}</h1>
                    <div className='product-stock'>
                        {product.stock > 20 ?
                        (
                            <div className='product-stock-in'>
                                În stoc
                            </div>
                        )
                        :
                        (
                            (cart.getProductQuantity(product.id) >= product.stock ? 
                            <div className='product-stock-low'>Stoc epuizat</div>
                            :
                            <div className='product-stock-low'>
                                Au mai rămas câteva produse
                            </div>
                        )
                        )}
                    </div>
                    <div className='product-price'>
                        {product.discountprice != product.storeprice ?
                        <>
                            <s>{product.discountprice},00 LEI</s> <span>{product.storeprice},00 LEI</span> <span className='discount'>{product.discount}% REDUCERE</span>
                        </>
                        :
                        <>
                        <span>{product.storeprice},00 LEI</span>
                        </>
                        }
                    </div>

                    <button className='container-add-to-cart' disabled={cart.getProductQuantity(product.id) >= product.stock} onClick = {() => cart.addOneToCart(product.id, product.product_id,product.title, product.storeprice,product.stock)}>{cart.getProductQuantity(product.id) >= product.stock ? <div>Adaugă în coș</div>:<div>Adaugă în coș<span></span></div>}</button>
                    <div className='product-info'>
                        <div className='product-info-c'>
                            <div className='i-shipping'></div>
                            <div>
                                Transport rapid în 1-2 zile.
                            </div>
                        </div>
                        <div className='product-info-c'>
                            <div className='i-return'></div>
                            <div>
                                14 zile drept de retur.
                            </div>
                        </div>
                        <div className='product-info-c'>
                            <div className='i-freeshipping'></div>
                            <div>
                                {product.storeprice >= 200
                                ?
                                <>
                                    Transport gratuit
                                </>
                                :
                                <>
                                    Transport gratuit la comenzi peste 200,00 lei
                                </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='product-description'>
                        <span dangerouslySetInnerHTML={{ __html: replaceImageTags(product.description) }}></span>
                    </div>
            </div>
        </div>
    );
}

export default ProductPage;