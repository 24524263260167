import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";
import {FetchProducts,productsArray} from '../ProductsList';

function HomePage(props){
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            document.title = props.title
            setLoading(true);
            try {
                await FetchProducts();
            } catch (err) {
                setError(true);
            }
            setLoading(false);
        };
        fetchProducts();
    }, []);

    if (loading) {
        return <div className='loading'></div>;
    }
    if (error) {
        return <div className='error'>A apărut o eroare. Te rog să dai refresh la pagină!</div>;
    }
    return(
        <div className='content'>
            <div  className='store'>
                {productsArray.map((product, idx) =>(
                    <ProductCard key={idx} product={product}/>
                ))}
            </div>
        </div>
    );
}
export default HomePage;