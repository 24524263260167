import axios from 'axios';

const productsArray = [];

function FetchProducts() {
    productsArray.length = 0;
    return axios.get("https://pixelplus.store/api/get-products.php")
        .then(response => {
            productsArray.push(...response.data);
        })
        .catch(err => {
            //
        });
}
function getProductData(id){
    let productData = productsArray.find(product => product.id === id)

    if(productData === undefined)
    {
        //console.log("Produsul nu exista");
        return undefined;
    }
    return productData;
}

export {FetchProducts,productsArray,getProductData}