import {useState,useContext, useEffect} from 'react';
import { CartContext } from './CartContext';
import { FetchProducts } from './ProductsList';
import PlaceOrderCart from './PlaceOrderCart';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

function PlaceOrder(props){
    const cart = useContext(CartContext);
    const [showPaymentMethod, setShowPaymentMethod] = useState('card');
    const productsCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [submitError, setSubmitError] = useState(false);
    const [submitErrorMessage, setSubmitErrorMessage] = useState('');
    const [submitOrder, setSubmitOrder] = useState(false);

    const navigate = useNavigate();

    const continueShopping = () => {
        navigate("/");
    }
    const togglePayment = (ev) => {
        setShowPaymentMethod(ev.target.value);
    }

    useEffect(() => {
        document.title = props.title;
        const fetchProducts = async () => {
            setLoading(true);
            try {
                await FetchProducts();
            } catch (err) {
                setError(true);
            }
            setLoading(false);
        };
        fetchProducts();
    }, []);
    
    if (loading) {
        return <div className='loading'></div>;
    }
    if (error) {
        return <div className='error'>A apărut o eroare. Te rog să dai refresh la pagină!</div>;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const formData = new FormData(form);
        formData.append('cart_data', JSON.stringify(cart.items));
        setSubmitOrder(true);
        axios.post('https://pixelplus.store/api/process-order.php', formData)
            .then(response => {
                if(response.data.error){
                    setSubmitError(true);
                    setSubmitErrorMessage(response.data.error);
                    setSubmitOrder(false);
                }
                if(response.data.url) {
                    window.location.assign(response.data.url);
                }
            })
            .catch(err => {
                setSubmitError(true);
                setSubmitErrorMessage('A apărut o eroare neașteptată. Va rugăm să încercați mai târziu.');
                setSubmitOrder(false);
            });
    };
    
    return(
        <div className='placeOrderBox'>
                {
                    submitOrder ?
                    <>
                    <div className='order-loading'>
                        <div className='loading'></div>
                    </div>
                    </>
                    :
                    <>
                    </>
                }
                {productsCount > 0 ?
                <>
                    <div className='submitDetails'>
                        <form  novalidate onSubmit={handleSubmit}>
                            <div className="form-floating mb-3">
                                <input type="email" name="email" className="form-control" id="email" placeholder="Email" required></input>
                                <label htmlFor="email">Email</label>
                            </div>
                            <div className="placeorder_terms mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name='oferte' value id='oferte'></input>
                                    <label className="form-check-label" htmlFor="oferte">
                                        Doresc să primesc oferte pe email
                                    </label>
                                </div>
                            </div>
                            <div className='placeOrder_text'>
                                <div>Adresa de livrare</div>
                                <span className='italic'>Adresa va fi folosită pentru livrare și pentru facturare</span>
                            </div>

                            <div className='placeorder_column'>
                                <div className="form-floating">
                                    <select className="form-select form-select-lg" name='tara' id="tara">
                                        <option value="Romania">România</option>
                                    </select>
                                    <label htmlFor="tara">Țara</label>
                                </div>
                            </div>
                            
                            <p className="d-inline-flex gap-1">
                                <button className="btn btn-outline-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#collapsepersoanajuridica" aria-expanded="false" aria-controls="collapsepersoanajuridica">
                                   Alege persoană juridică
                                </button>
                            </p>
                            
                            <div className='collapse' id="collapsepersoanajuridica">
                                <div className='placeOrder_text'>
                                    <div>Persoană juridică</div>
                                    <span className='italic'>Se completează doar dacă doriți factură pe persoană juridică.</span>
                                </div>
                                <div className='placeorder_column'>
                                    <div className="form-floating">
                                            <input type="text" name="persoanajuridica" className="form-control" id="persoanajuridica" placeholder="Persoana juridică"></input>
                                            <label htmlFor="nume">Persoană juridică</label>
                                    </div>
                                    <div className="form-floating">
                                            <input type="text" name="cui" className="form-control" id="cui" placeholder="CUI"></input>
                                            <label htmlFor="prenume">CUI</label>
                                    </div>
                                </div>
                                <hr></hr>
                            </div>
                            <div className='placeorder_column' >
                                <div className="form-floating">
                                        <input type="text" name="nume" className="form-control" id="nume" placeholder="Nume" required></input>
                                        <label htmlFor="nume">Nume</label>
                                </div>
                                <div className="form-floating">
                                        <input type="text" name="prenume" className="form-control" id="prenume" placeholder="Prenume" required></input>
                                        <label htmlFor="prenume">Prenume</label>
                                </div>
                            </div>
                            <div className="form-floating mb-3">
                                    <input type="text" name="adresa_1" className="form-control" id="adresa_1" placeholder="Adresă" required></input>
                                    <label htmlFor="adresa">Adresă</label>
                            </div>
                            <div className="form-floating mb-3">
                                    <input type="text" name="adresa_2" className="form-control" id="adresa_2" placeholder="Apartament, bloc, scară etc. (opțional)"></input>
                                    <label htmlFor="adresa_2">Apartament, bloc, scară etc. (opțional)</label>
                            </div>
                            <div className='placeorder_column'>
                                <div className="form-floating">
                                    <select className="form-select form-select-lg" name='judet' id="judet" required>
                                        <option value="">Alege județul</option>
                                        <option value="Alba">Alba</option>
                                        <option value="Arad">Arad</option>
                                        <option value="Arges">Argeș</option>
                                        <option value="Bacau">Bacău</option>
                                        <option value="Bihor">Bihor</option>
                                        <option value="Bistrita Nasaud">Bistrița Năsăud</option>
                                        <option value="Botosani">Botoșani</option>
                                        <option value="Brasov">Brașov</option>
                                        <option value="Braila">Brăila</option>
                                        <option value="Bucuresti">București</option>
                                        <option value="Buzau">Buzău</option>
                                        <option value="Caras Severin">Caraș Severin</option>
                                        <option value="Calarasi">Călărasi</option>
                                        <option value="Cluj">Cluj</option>
                                        <option value="Constanta">Constanța</option>
                                        <option value="Covasna">Covasna</option>
                                        <option value="Dambovita">Dâmbovița</option>
                                        <option value="Dolj">Dolj</option>
                                        <option value="Galati">Galați</option>
                                        <option value="Giurgiu">Giurgiu</option>
                                        <option value="Gorj">Gorj</option>
                                        <option value="Harghita">Harghita</option>
                                        <option value="Hunedoara">Hunedoara</option>
                                        <option value="Ialomita">Ialomița</option>
                                        <option value="Iasi">Iași</option>
                                        <option value="Ilfov">Ilfov</option>
                                        <option value="Maramures">Maramureș</option>
                                        <option value="Mehedinti">Mehedinti</option>
                                        <option value="Mures">Mureș</option>
                                        <option value="Neamt">Neamț</option>
                                        <option value="Olt">Olt</option>
                                        <option value="Prahova">Prahova</option>
                                        <option value="Satu Mare">Satu Mare</option>
                                        <option value="Salaj">Sălaj</option>
                                        <option value="Sibiu">Sibiu</option>
                                        <option value="Suceava">Suceava</option>
                                        <option value="Teleorman">Teleorman</option>
                                        <option value="Timis">Timiș</option>
                                        <option value="Tulcea">Tulcea</option>
                                        <option value="Vaslui">Vaslui</option>
                                        <option value="Valcea">Valcea</option>
                                        <option value="Vrancea">Vrancea</option>
                                    </select>
                                    <label htmlFor="judet">Județul</label>
                                </div>
                                <div className="form-floating">
                                        <input type="text" name='localitate' className="form-control" id="localitate" placeholder="Localitate" required></input>
                                        <label htmlFor="localitate">Localitate</label>
                                </div>
                                <div className="form-floating">
                                        <input type="text" name='codpostal' className="form-control" id="codpostal" placeholder="Cod poștal" required pattern="[0-9 ]+"></input>
                                        <label htmlFor="codpostal">Cod poștal</label>
                                </div>
                            </div>
                            <div className="form-floating mb-3">
                                    <input type="text" name='telefon' className="form-control" id="telefon" placeholder="Telefon" required pattern="[0-9 ]+"></input>
                                    <label htmlFor="telefon">Telefon</label>
                            </div>
                            <div className='placeOrder_text italic'>
                                <span>Veți putea fi contactat telefonic în cazul în care vor fi necesare clarificări sau detalii suplimentare privind livrarea comenzii.</span>
                            </div>
                            <hr></hr>
                            <div className='mb-3'>
                                <div className='placeOrder_text'>
                                    <div>Alege o modalitate de plată</div>
                                </div>
                                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="modalitate_plata" value="card" id="card" autoComplete="on" checked={showPaymentMethod === 'card'}  onChange={togglePayment}/>
                                    <label className="btn btn-outline-secondary" htmlFor="card">Plata cu cardul</label>

                                    <input type="radio" className="btn-check" name="modalitate_plata" value="ramburs" id="ramburs" autoComplete="on" checked={showPaymentMethod==='ramburs'} onChange={togglePayment}/>
                                    <label className="btn btn-outline-secondary" htmlFor="ramburs">Plata la ramburs</label>
                                </div>
                            </div>
                            {showPaymentMethod === 'card' ? 
                            <>
                                <div id='payment_type'>
                                    <div className='placeOrder_text'>
                                            <span>După finalizarea comenzii, veți fi redirecționat către pagina de plată cu cardul pentru a finaliza tranzacția.</span>
                                            <div className='accepted-cards'>
                                                <div className='card visa'></div>
                                                <div className='card mastercard'></div>
                                                <div className='card americanexpress'></div>
                                                <div className='card unionpay'></div>
                                                <div className='card applepay'></div>
                                                <div className='card googlepay'></div>
                                            </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div id='payment_type2'>
                                    <div className='placeOrder_text'>
                                            <span>Plata pentru această comandă va fi realizată la livrare.</span>
                                            <div className='accepted-cards'>
                                                <div className='shipping'></div>
                                                <div className='money'></div>
                                                <div className='box'></div>
                                            </div>
                                    </div>
                                </div>
                            </>
                            }
                            <hr></hr>
                            <div className="placeorder_terms mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id='termenisiconditii' required></input>
                                    <label className="form-check-label" htmlFor="termenisiconditii">
                                        Sunt de acord cu <Link to='/termeni-si-conditii'>termenii și condițiile</Link>
                                    </label>
                                </div>
                            </div>
                            <div className="placeorder_terms mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id='completat' required></input>
                                    <label className="form-check-label" htmlFor="completat">
                                        Datele completate mai sus sunt corecte
                                    </label>
                                </div>
                            </div>
                            {submitError ?
                            <>
                                <div className='submit-order-error'>
                                    {submitErrorMessage}
                                </div>
                            </>
                            :
                            <></>
                            }
                            <Button type='submit' variant="success purchase-btn">
                                Plasează comanda
                            </Button>
                        </form>
                    </div>
                    <div className='order-details-box'>
                        {cart.items.map((currentProduct, idx) => (
                            <PlaceOrderCart key={idx} id={currentProduct.id} quantity={currentProduct.quantity}></PlaceOrderCart>
                        ))}
                        <div className='finalPriceBox'>
                            <div>
                                <span>Preț livrare</span> 
                                <span>10.00 lei</span></div>
                            <div>
                                <span>Preț total</span>
                                <span>{cart.getTotalCost().toFixed(2)} lei</span>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className='emptyCart'>
                            <div>Coșul tău de cumpărături este gol</div>
                            <div className='cart-continue'>
                                <Button onClick={continueShopping} className="btn btn-primary" type="button">
                                        Continuă cumpărăturile
                                </Button>
                            </div>
                    </div>
                </>
                }
        </div>
    );
}

export default PlaceOrder;