import {createContext, useState, useEffect} from 'react';
import {getProductData } from './ProductsList';

export const CartContext = createContext({
    items: [],
    getProductQuantity: () => {},
    addOneToCart: () => {},
    removeOneFromCart: () => {},
    deleteFromCart: () => {},
    getTotalCost: () => {},
    clearCart: () => {}
});

export function CartProvider({children}) {
    const [cartProducts, setCartProducts] = useState(() => {
        const storedCart = sessionStorage.getItem('cartProducts');
        return storedCart ? JSON.parse(storedCart) : [];
    });

    useEffect(() => {
        sessionStorage.setItem('cartProducts', JSON.stringify(cartProducts));
    }, [cartProducts]);
    
    const contextValue = {
        items: cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost,
        clearCart
    }
    
    //Get the product/s quantity
    function getProductQuantity(id) {
        const quantity = cartProducts.find(product => product.id === id)?.quantity;
        if(quantity === undefined)
        {
            return 0;
        }
        return quantity;
    }

    //Add one product to the cart
    function addOneToCart(id,product_id,name,price,stock) {
        const quantity = getProductQuantity(id);

        if(quantity < stock)
        {
            if(quantity === 0){
                setCartProducts (
                    [
                        ...cartProducts,
                        {
                            id: id,
                            product_id:product_id,
                            quantity: 1,
                            product_name:name,
                            product_price:price
                        }
                    ]
                )
            } else {
                setCartProducts(
                    cartProducts.map(
                        product =>
                            product.id === id
                            ? {...product, quantity: product.quantity + 1}
                            : product
                    )
                )
            }
        }
    }

    //Remove a product from the cart
    function removeOneFromCart(id) {
        const quantity = getProductQuantity(id);
        if(quantity == 1) {
            deleteFromCart(id);
        } else {
            setCartProducts(
                cartProducts.map(
                    product =>
                        product.id === id
                        ? {...product, quantity: product.quantity - 1}
                        : product
                )
            )
        }
    }

    //Delete the product from the cart
    function deleteFromCart(id){
        setCartProducts(
            cartProducts =>
                cartProducts.filter(currentProduct => {
                    return currentProduct.id != id;
                })
        )
    }

    //Get the total price for the entire cart
    function getTotalCost() {
        let totalCost = 0;
        cartProducts.map((cartItem) => {
            const productData = getProductData(cartItem.id);
            if (productData) {
                totalCost += (productData.storeprice * cartItem.quantity);

            }
        });
        return totalCost;
    }

    function clearCart(){
        setCartProducts([]);
    }
    return (
    <CartContext.Provider value={contextValue}>
        {children}
    </CartContext.Provider>
    )
}

export default CartProvider;