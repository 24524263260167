import { useEffect } from "react";

function PoliticaRetur(props)
{
    useEffect(() => {
        document.title = props.title;
    }, []);
    return(
        <div className='content-box'>
            <h2>Politica de returnare</h2>
            <span><i>Pixelplus.store</i> oferă clienților săi serviciul de returnare al produselor conform legislației în vigoare și politicii interne.</span>

            <h2>Defecte sau Deteriorări</h2>
            <span>Vă rugăm să inspectați comanda la primire și să ne contactați imediat dacă articolul este defect, deteriorat sau dacă ați primit un articol greșit. În cazul coletelor cu deteriorări vizibile la livrare, vă recomandăm să refuzați primirea acestora și să solicitați întocmirea unui proces verbal împreună cu reprezentantul firmei de curierat.</span>

            <h2>Dreptul de Retragere</h2>
            <span>Utilizatorii au dreptul să revină asupra deciziei de cumpărare într-un termen de 14 zile calendaristice de la primirea produsului.</span>

            <h2>Condiții de Returnare</h2>
            <span>Pentru ca un retur să fie acceptat, produsul trebuie să fie în stare nouă, neutilizat, cu toate etichetele intacte și ambalajul original. Produsele care nu îndeplinesc aceste condiții nu vor fi acceptate pentru returnare.</span>

            <h2>Procedura de Returnare</h2>
            <span>Pentru a iniția un retur, vă rugăm să ne contactați la <i>contact[at]pixelplus.store</i> și să ne furnizați următoarele informații:</span>
            <span><i>Numărul comenzii</i></span>
            <span><i>Motivele pentru returnare</i></span>
            <span><i>Fotografii ale produsului (dacă este cazul)</i></span>
            <span><i>După verificarea solicitării dumneavoastră, vă vom oferi instrucțiuni detaliate pentru returnare.</i></span>
            <span><i>Returul produselor se face prin intermediul firmei de curierat, cu ridicare de la adresa de livrare.</i></span>

            <h2>Restituirea Banilor</h2>
            <span>După primirea și verificarea produsului returnat, vom procesa restituirea banilor în termen de 14 zile.</span>
            <span>Restituirea se va face prin virament bancar.</span>

            <h2>Cheltuieli de Transport</h2>
            <span>Utilizatorii vor suporta costurile de transport pentru returnarea produselor. Suma returnată va include doar prețul produsului, excluzând costurile de livrare inițiale.</span>

            <span>Vă informăm că cheltuielile de transport pentru returnarea produselor sunt suportate de utilizator, cu excepția situațiilor în care produsul este defect.</span>
            <span>Produsele personalizate sau produsele care prezintă semne de utilizare nu vor fi acceptate pentru retur.</span>
            
            <h2>Articole Returnate Fără Preaviz</h2>
            <span>Produsele trimise înapoi fără a solicita mai întâi returul nu vor fi acceptate.</span>

            <h2>Contact pentru Întrebări</h2>
            <span>Pentru orice întrebări legate de returnări, nu ezitați să ne contactați prin e-mail la <i>contact[at]pixelplus.store</i>.</span>
        </div>
    )
}

export default PoliticaRetur;