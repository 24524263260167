import { useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { Button } from 'react-bootstrap';

function Error404(props) {
    const navigateTo  = useNavigate();
    const continueShopping = () => {
        navigateTo("/");
    }
    useEffect(() => {
        document.title = props.title;
    }, []);
    return(
        <>
            <div className='error'>
                <div className='error_show'>
                    <div className='error-icon'></div> <div>Această pagină nu există</div>
                </div>
                <div className='cart-continue'>
                    <Button onClick={continueShopping} className="btn btn-primary" type="button">
                            Continuă cumpărăturile
                    </Button>
                </div>
            </div>
        </>
    );
}
export default Error404;