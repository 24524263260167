import { useContext,useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { CartContext } from '../CartContext';

function SuccessPage(props){
    const { clearCart } = useContext(CartContext);

    useEffect(() => {
        document.title = props.title;
        clearCart();
    }, [clearCart]);

    const navigateTo  = useNavigate();
    const continueShopping = () => {
        navigateTo("/");
    }
    return(
        <>
            <div className='success'>
                <div className='success_show'>
                    <div className='success-icon'></div> <div>Plata a fost efectuată cu succes!</div>
                </div>
                <div className='succes_info'>
                    Coletul va fi livrat în 24-48 de ore.
                </div>
                <div className='cart-continue'>
                    <Button onClick={continueShopping} className="btn btn-primary" type="button">
                            Continuă cumpărăturile
                    </Button>
                </div>
            </div>
        </>
    );
}
export default SuccessPage;