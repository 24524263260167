import { useEffect } from 'react';

function Politici(props)
{
    useEffect(() => {
        document.title = props.title;
    }, []);
    return(
        <>
        <div className='content-box'>
            <h2>Politica de Confidențialitate</h2>
            <span>Această politică de confidențialitate explică modul în care <i>pixelplus.store</i> colectează, utilizează și protejează informațiile personale pe care le furnizați atunci când vizitați site-ul nostru sau efectuați o achiziție.</span>

            <span>Ne angajăm să respectăm confidențialitatea informațiilor dumneavoastră personale, în conformitate cu Regulamentul general privind protecția datelor (GDPR) și cu orice altă legislație aplicabilă pe teritoriul României.</span>

            <span>Ne rezervăm dreptul de a actualiza această politică de confidențialitate atunci când este necesar. Orice modificare va fi publicată pe această pagină, așa că vă rugăm să verificați periodic conținutul acestei politici pentru a fi la curent cu orice actualizări sau modificări. Vă încurajăm să citiți cu atenție această politică pentru a înțelege practicile noastre privind informațiile personale și modul în care acestea sunt gestionate.</span>

            <h2>1. Informațiile pe care le Colectăm</h2>
            <span>Colectăm următoarele tipuri de informații de la utilizatorii noștri: <i>nume</i>, <i>adresă de e-mail</i>, <i>adresă de livrare</i> și <i>număr de telefon</i>.</span>

            <span>Colectăm datele dvs. cu caracter personal direct de la dumneavoastră, astfel încât aveți controlul asupra tipului de informație pe care ne-o oferiți. De exemplu, atunci când plasați o comandă pe <i>pixelplus.store</i>, ne furnizați informații precum produsul dorit, numele și prenumele, adresa de livrare și numărul de telefon.</span>

            <span>Este important să menționăm că <i>nu colectăm informații de plată direct</i>, deoarece toate plățile sunt procesate prin <i>Stripe</i>. Utilizatorii pot alege între plata ramburs și plata cu cardul.</span>

            <span>Pentru mai multe informații despre modul în care Stripe gestionează datele de plată, vă rugăm să consultați: <a href='https://stripe.com/en-ro/privacy' target='_blank' rel='nofollow'>https://stripe.com/en-ro/privacy</a>.</span>

            <span><i>Site-ul nostru nu utilizează cookies.</i></span>


            <h2>2. Modul de Colectare a Informațiilor</h2>

            <span>Informațiile sunt colectate prin intermediul formularului disponibil pe site, completat de utilizatori în momentul plasării unei comenzi. Acest proces ne permite să gestionăm comenzile eficient și să comunicăm cu clienții noștri. </span>

            <h2>3. Scopul Colectării Informațiilor</h2>
            <span>Informațiile colectate sunt utilizate pentru următoarele scopuri:</span>
            <span><i>Procesarea comenzilor:</i> Utilizăm informațiile pentru a completa comenzile plasate pe site.</span>
            <span><i>Livrarea produselor:</i> Informațiile de livrare sunt necesare pentru a trimite produsele la adresa indicată.</span>
            <span><i>Comunicarea cu clienții:</i> Vă putem contacta prin e-mail sau telefon pentru a vă informa despre statusul comenzii sau pentru a rezolva eventualele întrebări și probleme.</span>
            <span><i>Prelucrarea comenzilor:</i> Acest proces include preluarea, expedierea și facturarea comenzilor.</span>
            <span><i>Rezolvarea anulărilor:</i> Ne ocupăm de orice probleme legate de comenzile efectuate, bunurile sau serviciile achiziționate.</span>
            <span><i>Returnarea produselor:</i> Asigurăm returnarea produselor conform prevederilor legale.</span>
            <span><i>Rambursarea contravalorii produselor:</i> Oferim rambursări conform prevederilor legale.</span>
            <span><i>Asigurarea serviciilor de suport:</i> Răspundem întrebărilor referitoare la comenzile dvs. sau la bunurile și serviciile disponibile pe <i>pixelplus.store</i>.</span>

            <h2>4. Partajarea Informațiilor</h2>
            <span>Informațiile dumneavoastră personale nu vor fi partajate cu terțe părți, cu excepția următoarelor situații:
            Servicii de livrare: Informațiile pot fi partajate cu companiile de livrare pentru a asigura livrarea produselor.</span>

            <h2>5. Stocarea Informațiilor</h2>
            <span>Informațiile personale colectate vor fi stocate atât timp cât este necesar pentru a îndeplini scopurile pentru care au fost colectate, inclusiv pentru a respecta obligațiile legale, a soluționa dispute și a aplica acordurile noastre.</span>

            <h2>6. Securitatea Informațiilor</h2>
            <span>Site-ul nostru este găzduit pe servere securizate în Google Cloud, care protejează informațiile împotriva accesului neautorizat, utilizării abuzive sau distrugerii. Luăm măsuri tehnice și organizatorice adecvate pentru a asigura securitatea informațiilor dumneavoastră personale.</span>

            <h2>7. Drepturile Utilizatorilor</h2>
            <span>Utilizatorii au dreptul de a solicita ștergerea informațiilor personale. Dacă doriți să exercitați aceste drepturi sau aveți întrebări cu privire la informațiile dumneavoastră personale, vă rugăm să ne contactați.</span>

            <h2>8. Contact</h2>
            <span>Dacă aveți întrebări suplimentare cu privire la această <i>Politică de Confidențialitate</i> sau despre practicile noastre, nu ezitați să ne contactați prin e-mail la <i>contact[at]pixelplus.store</i>.</span>
        </div>
        </>
    )
}

export default Politici;