import { useEffect } from "react";
function Contact(props){
    useEffect(() => {
        document.title = props.title;
    }, []);
    return(
        <></>
    )
}

export default Contact;