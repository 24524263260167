import { useEffect } from "react";

function PoliticaLivrare(props)
{
    useEffect(() => {
        document.title = props.title;
    }, []);
    return(
        <>
        </>
    )
}

export default PoliticaLivrare;