import { useEffect,useState } from 'react';
import { FetchProducts, getProductData } from './ProductsList';
function YourCart(props) {
    const id = props.id;
    const quantity = props.quantity;
    const product = getProductData(id);
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            setLoading(true);
            try {
                await FetchProducts();
            } catch (err) {
                setError(true);
            }
            setLoading(false);
        };
        fetchProducts();
    }, []);
    
    if (loading) {
        return '';
    }
    if (error) {
        return '';
    }
    return(
        <>
        <div className='cartBox'>
            <div className='cartImage'>
            <img alt={product.title} src={`https://pixelplus.store/store/${product.url}/${product.url}.png`}/>
            </div>
            <div className='cartTitle'>
                <span>{product.title}</span>
                <span>{quantity} BUC</span>
            </div>
            <div className='productTotal'>
                {(quantity * product.storeprice).toFixed(2)} lei
            </div>
        </div>
        </>
    )
}

export default YourCart;