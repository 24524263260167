import { useEffect } from 'react';

function Termeni(props){
    useEffect(() => {
        document.title = props.title;
    }, []);
    return(
        <>
        <div className='content-box'>
            <h2>Termeni și conditii</h2>
            <span>Acest site web, denumit <i>Pixelplus.store</i>, este destinat utilizatorilor care accesează informațiile, instrumentele și serviciile disponibile. Termenii "noi", "ne" și "nostru" se referă la <i>Pixelplus.store</i>. Prin utilizarea acestui site și achiziționarea de produse de la noi, confirmați că ați citit și sunteți de acord cu acești termeni și condiții ("Termeni și Condiții" sau "Termeni").</span>

            <span>Vă rugăm să citiți cu atenție acești Termeni înainte de a accesa sau utiliza site-ul nostru. Acești Termeni se aplică tuturor utilizatorilor site-ului. Prin accesarea sau utilizarea oricărei părți a site-ului, sunteți de acord să respectați acești Termeni. Dacă nu sunteți de acord cu toți termenii și condițiile, nu puteți accesa site-ul și nu puteți utiliza serviciile noastre.</span>

            <span>Orice funcționalitate sau instrument nou adăugat pe site va fi, de asemenea, supus acestor Termeni. Puteți consulta în orice moment cea mai recentă versiune a Termenilor pe această pagină. Ne rezervăm dreptul de a actualiza, modifica sau înlocui orice parte a acestor Termeni prin publicarea de actualizări pe site-ul nostru. Este responsabilitatea dumneavoastră să verificați periodic această pagină pentru a fi la curent cu eventualele modificări. Continuarea utilizării sau accesul la site după postarea modificărilor constituie acceptarea acestor modificări.</span>

            <span>Plățile efectuate pe site sunt procesate prin platforma <i>Stripe</i>, asigurând astfel un mod sigur și eficient de a achiziționa produsele și serviciile noastre.</span>

            <span>Site-ul nostru poate conține link-uri către alte site-uri web. <i>Pixelplus.store</i> nu își asumă responsabilitatea pentru politica de confidențialitate sau conținutul acestor site-uri externe.</span>

            <h2>Modificări ale prețurilor și serviciului</h2>

            <span>Prețurile produselor noastre pot fi modificate fără o notificare prealabilă. Ne rezervăm dreptul de a ajusta sau suspenda orice aspect al serviciului, inclusiv conținutul și produsele disponibile. </span>

            <h2>Comenzi și Plăți</h2>

            <span>Toate comenzile sunt supuse acceptării noastre. Acceptăm următoarele metode de plată:</span>
            <span><i>Plata cu cardul prin platforma Stripe:</i> <a href="https://stripe.com/en-ro/privacy" target="_blank" rel="nofollow">https://stripe.com/en-ro/privacy</a></span>
            <span><i>Plata ramburs:</i> Plata cu numerar se face în RON, integral, la livrare, direct către firma de curierat pe bază de chitanță.</span>
            <span>Produsele pot fi returnate în conformitate cu politica noastră de returnare.</span>
            <span>Anumite produse sau servicii disponibile pe acest site <i>pot avea stocuri limitate</i>. Ne străduim să prezentăm cât mai exact culorile și imaginile produselor, însă nu garantăm că acestea vor fi afișate exact cum sunt în realitate pe ecranul dumneavoastră.</span>
            <span>Ne rezervăm dreptul de a limita vânzările de produse sau servicii la anumite persoane, regiuni geografice sau jurisdicții, la discreția noastră. De asemenea, putem restricționa cantitățile de produse sau servicii disponibile pentru achiziție.</span>
            <span>Toate descrierile și prețurile produselor pot fi modificate în orice moment, fără o notificare prealabilă, la discreția noastră. De asemenea, ne rezervăm dreptul de a retrage orice produs de la vânzare în orice moment. Nu garantăm că produsele, serviciile sau informațiile pe care le achiziționați vor corespunde întocmai așteptărilor dumneavoastră sau că eventualele erori întâmpinate vor fi corectate.</span>
            <span>Promoțiile și ofertele prezentate pe site sunt valabile în limita stocului disponibil.</span>

            <h2>Informații de facturare</h2>
            <span>Ne rezervăm dreptul de a refuza orice comandă plasată pe site, la discreția noastră. De asemenea, putem limita sau anula comenzi în cazul în care suspectăm comportamente frauduloase, erori în prețuri sau alte nereguli. În situația în care este necesar să modificăm sau să anulăm o comandă, vom încerca să vă informăm cât mai rapid posibil, utilizând emailul sau numărul de telefon furnizat la plasarea comenzii.</span>

            <span>Este responsabilitatea dumneavoastră să furnizați informații corecte, complete și actualizate pentru facturare și livrare, pentru a asigura procesarea comenzii fără probleme. Dacă observați că ați introdus informații incorecte sau incomplete, care ar putea afecta livrarea comenzii, vă rugăm să ne contactați imediat pentru a corecta aceste date. <i>Pixelplus.store</i> nu își asumă responsabilitatea pentru întârzieri sau erori de livrare cauzate de informații incorecte sau incomplete furnizate la plasarea comenzii.</span>

            <h2>Drepturile și Obligațiile Utilizatorilor</h2>

            <span>Utilizatorii au dreptul de a:</span>
            <span>Solicita informații despre comenzile lor.</span>
            <span>Primi suport și asistență în legătură cu produsele achiziționate, inclusiv în caz de returnare sau remedierea eventualelor probleme legate de produse.</span>
            <span>Beneficia de drepturile prevăzute de legislația aplicabilă, inclusiv dreptul de retragere din contract conform politicii de returnare.</span>
           
            <span>Utilizatorii sunt obligați să:</span>
            <span>Furnizeze informații corecte, complete și actualizate în timpul procesului de comandă, inclusiv adresa de livrare, datele de facturare și informațiile de contact.</span>
            <span>Respecte acești Termeni și Condiții în utilizarea site-ului și în procesul de achiziție a produselor.</span>
            <span>Nu folosească site-ul în scopuri ilegale sau pentru a încălca drepturile altor persoane.</span>
            <span>Informeze prompt <i>Pixelplus.store</i> în cazul în care observă orice erori în detaliile comenzii sau în informațiile personale furnizate.</span>
            <span>În cazul în care utilizatorii nu respectă aceste obligații, <i>ne rezervăm dreptul de a anula comenzi sau de a restricționa accesul la anumite servicii.</i></span>

            <h2>Politica de Returnare</h2>
            <span>Clienții au dreptul să returneze produsele achiziționate în termen de 14 zile calendaristice de la primirea acestora. Pentru a fi acceptat, produsul trebuie să fie în stare nouă, neutilizat, cu etichetele intacte și ambalajul original.</span>

            <span>Cheltuielile de transport pentru retur sunt suportate de utilizator, cu excepția cazurilor în care produsul este defect. Restituirea banilor se va face în termen de 14 zile de la primirea produsului returnat, prin virament bancar, excluzând costurile de livrare inițiale.</span>

            <span>Pentru detalii suplimentare, consultați politica de returnare completă disponibilă pe site.</span>

            <h2>Limitarea Responsabilității</h2>

            <span><i>Pixelplus.store</i> nu va fi responsabil pentru daune directe sau indirecte care pot apărea din utilizarea sau incapacitatea de a utiliza site-ul sau produsele noastre. Aceasta include, dar nu se limitează la, daune rezultate din:</span>
            
            <span>Erori în conținutul site-ului - utilizarea sau incapacitatea de a utiliza site-ul;</span>
            <span>Defecțiuni tehnice sau întârzieri la livrare;</span>
            <span>Utilizarea incorectă sau necorespunzătoare a produselor achiziționate de pe site;</span>
            <span>Pierderea sau deteriorarea produselor de către utilizatori după livrare.</span>
            <span><i>Pixelplus.store</i> nu va fi responsabil pentru daune directe sau indirecte care pot apărea din utilizarea sau incapacitatea de a utiliza site-ul sau produsele noastre. Aceasta include, dar nu se limitează la, daune rezultate din:</span>
            
            <span>Erori în conținutul site-ului;</span>
            <span>Defecțiuni tehnice sau întârzieri la livrare;</span>
            Utilizarea incorectă sau necorespunzătoare a produselor achiziționate de pe site;
            <span>Pierderea sau deteriorarea produselor de către utilizatori după livrare;</span>
            <span>În cazul în care un produs este considerat necorespunzător sau defect, responsabilitatea <i>Pixelplus.store</i> se limitează la prețul plătit pentru produsul respectiv.</span>
            <span>Nu ne asumăm responsabilitatea pentru daunele indirecte sau consecințele utilizării produselor achiziționate.</span>
            <span><i>Pixelplus.store</i> nu va fi responsabil pentru întârzieri sau neîndepliniri ale obligațiilor sale rezultând din evenimente neprevăzute, inclusiv, dar fără a se limita la, calamități naturale, pandemii, greve, blocaje sau alte circumstanțe care depășesc controlul nostru. În cazul unui astfel de eveniment, ne vom strădui să informăm utilizatorii despre întârzierile sau neîndeplinirile respective și vom lua toate măsurile rezonabile pentru a minimiza impactul acestora.</span>

            <h2>Confidențialitate și Protecția Datelor</h2>
            <span>La <i>Pixelplus.store</i>, ne angajăm să protejăm informațiile personale ale utilizatorilor noștri. Colectăm și utilizăm informațiile personale doar în scopuri legitime, cum ar fi procesarea comenzilor si comunicarea cu utilizatorii. Informațiile pe care le colectăm sunt utilizate în conformitate cu <a href="/politici-de-confidentialitate" rel="nofollow">Politica de confidențialitate</a>, care detaliază modul în care gestionăm datele personale. Ne conformăm reglementărilor GDPR și ne asigurăm că informațiile dumneavoastră sunt tratate cu responsabilitate și respect.</span>

            <h2>Modificarea Termenilor</h2>
            <span>Ne rezervăm dreptul de a modifica acești Termeni și Condiții în orice moment. Orice modificare va fi publicată pe această pagină. Vă încurajăm să verificați periodic acești termeni pentru a fi la curent cu orice actualizări.</span>

            <h2>Acceptarea Termenilor</h2>
            <span>Prin accesarea site-ului <i>Pixelplus.store</i> și efectuarea comenzilor, utilizatorii confirmă că au citit, înțeles și acceptat acești Termeni și Condiții. Acceptarea acestor termeni este esențială pentru utilizarea site-ului și a serviciilor oferite. Dacă utilizatorii nu sunt de acord cu acești Termeni, sunt rugați să nu acceseze site-ul sau să nu efectueze comenzi. Continuarea utilizării site-ului constituie o acceptare a eventualelor modificări aduse acestor Termeni.</span>

            <h2>Legea Aplicabilă</h2>
            <span>Acești Termeni și Condiții sunt reglementați de legislația din România. Orice dispută care decurge din utilizarea Site-ului va fi soluționată în conformitate cu legislația română.</span>

            <h2>Date de Contact</h2>
            <span>Pentru întrebări legate de acești Termeni și Condiții, vă rugăm să ne contactați prin e-mail la <i>contact[at]pixelplus.store</i>.</span>
        </div>
        </>
    )
}

export default Termeni;